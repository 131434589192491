import React from "react"
import { connect, useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import useLangSwitch from "components/hooks/useLanguageSwicth"
import usePrice from "components/hooks/usePrice"
import { FaHeart, FaRegHeart, FaRegStar, FaStar } from "react-icons/fa"
import moment from "moment"
import getDifferenceInDays from "components/hooks/useGetDifferenceInDays"
import { defualtProd } from "assets/imgs/logos"
import useWishlistAction from "components/hooks/useWishlistAction"
import { useWindowSize } from "react-use"
import useCloudinary from "components/hooks/useCloudinaryImage"
import { useRemoveSpace } from "components/hooks/useRemoveSpace"
import LazyLoad from "react-lazyload"
import { companyName } from "project.config"
import Rating from "react-rating"

const ProductCardOne = ({ product, className }) => {
  const dispatch = useDispatch()

  const { favorites } = useSelector(state => ({
    favorites: state.Cart.favorites,
  }))

  const user_favourite = favorites?.favourites
  // storage.get("user_favourite") !== null && storage.get("user_favourite")

  // useEffect(() => {
  //   dispatch(getFavouriteItems())
  // }, [])

  const handleWishlist = productId => {
    useWishlistAction(dispatch, productId)
  }
  // console.log(product)
  const language = useLangSwitch(product?.primaryLang, product?.secondaryLang)

  const { currencyCode, oldPrice, price, stock, currentVarient } =
    usePrice(product)

  const createdAt = moment(product?.createdAt).format("MM/DD/YYYY")
  const today = moment(new Date()).format("MM/DD/YYYY")

  const diffInDays = getDifferenceInDays(createdAt, today)

  const diff = Math.abs(oldPrice - price)

  const discountPercentage =
    (oldPrice ?? 0) > price ? Math.floor((diff / oldPrice) * 100) : ""

  const { width } = useWindowSize()

  return (
    <div className={`product-cart-wrap shoplist-child mb-30 ${className}`}>
      <div className="product-img-action-wrap shoplist flex-1 position-relative">
        <div className="product-img product-img-zoom">
          <Link to={`/products/${useRemoveSpace(language?.name)}`}>
            <img
              className="default-img"
              src={`${
                useCloudinary(product.images[0]?.url, "w_500") ?? defualtProd
              }`}
              alt={`${companyName}-defualt-img`}
              title={`${companyName}-defualt-img`}
              loading="eager"
            />
            {width >= 480 ? (
              <LazyLoad height={200}>
                <img
                  className="hover-img"
                  src={
                    useCloudinary(
                      product.images[1]?.url
                        ? product.images[1]?.url
                        : product.images[0]?.url,
                      "w_500"
                    ) ?? defualtProd
                  }
                  alt={`${companyName}-product`}
                  title={`${companyName}-product`}
                  loading="eager"
                />
              </LazyLoad>
            ) : (
              ""
            )}
          </Link>
        </div>

        <div className="product-action-1">
          <a
            aria-label="Add To Wishlist"
            className="action-btn"
            onClick={e => handleWishlist(product?._id)}
          >
            {user_favourite?.length >= 1 &&
            user_favourite?.find(
              item => item?.product?._id === product?._id
            ) ? (
              <FaHeart className="text-danger" style={{ marginTop: "0px" }} />
            ) : (
              <FaRegHeart className="text-black" style={{ marginTop: "0px" }} />
            )}
          </a>
        </div>

        <div
          className="product-badges product-badges-position 
          product-badges-mrg"
        >
          {stock <= 0 ? (
            <span className="text-white best " style={{ zIndex: 5 }}>
              Sold out
            </span>
          ) : (
            <>
              {product?.isFeatured && <span className="hot curvy">Hot</span>}
              {diffInDays >= 1 && diffInDays <= 2 && (
                <span className="new curvy">New</span>
              )}
            </>
          )}
        </div>
      </div>
      <div className="product-content-wrap pt-1 w-100 d-flex flex-column text-center align-item-center">
        <h2 className="mb-0 mt-2 product-title ">
          <Link
            to={`/products/${useRemoveSpace(language?.name)}`}
            className="font-normal"
            style={{ fontWeight: "normal text-black" }}
          >
            <span>
              {language?.name?.slice(0, 86)} &nbsp;{" "}
              {currentVarient && (
                <span style={{ whiteSpace: "nowrap" }}>({currentVarient})</span>
              )}{" "}
            </span>
          </Link>
        </h2>

        {/* <div className="product-card-bottom mt-0">
          <div className="product-price">
            {oldPrice && (
              <>
                <span
                  className="old-price font-normal m-0"
                  style={{ fontWeight: "500" }}
                >
                  {oldPrice}
                </span>
                &nbsp;
              </>
            )}
            <span
              className="font-head text-black"
              style={{ margin: "0 5px 0 5px", fontWeight: "500" }}
            >
              {currencyCode}
              {price}&nbsp;
            </span>
            {(product?.discountPercentage > 0 || discountPercentage > 0) && (
              <span className="price-per">
                {product?.discountPercentage ?? discountPercentage}% off
              </span>
            )}
          </div>
        </div> */}

        {product?.averageRating >= 1 && (
          <div className="d-flex flex-column text-center justify-content-center align-items-center">
            <div className="star-container">
              <Rating
                emptySymbol={<FaRegStar />}
                fullSymbol={<FaStar />}
                className="text-warning me-1"
                initialRating={product?.averageRating || 0}
                style={{ pointerEvents: "none" }}
              />
              {width <= 480 ? (
                <span className="pt-1">({product?.reviewCount || 0})</span>
              ) : (
                <span className="text">
                  Reviews({product?.reviewCount || 0})
                </span>
              )}
            </div>
          </div>
        )}

        <div className="d-flex flex-column text-center justify-content-center align-items-center">
          <div
            className="star-container"
            style={{ minHeight: product?.reviewCount ? "" : "0px" }}
          ></div>

          <div className="price d-flex align-item-center">
            <div className="product-card-bottom mt-0">
              <div className="product-price">
                {oldPrice && (
                  <>
                    <span
                      className="old-price font-normal m-0"
                      style={{ fontWeight: "500" }}
                    >
                      {currencyCode}&nbsp;
                      {oldPrice}
                    </span>
                    &nbsp;
                  </>
                )}

                {price && (
                  <span
                    className="font-head text-black"
                    style={{ margin: "0 5px 0 5px", fontWeight: "500" }}
                  >
                    {currencyCode}
                    {currencyCode === "RS" ? "." : ""} {price}&nbsp;
                  </span>
                )}

                {(product?.discountPercentage > 0 ||
                  discountPercentage > 0) && (
                  <span className="price-per">
                    {product?.discountPercentage ?? discountPercentage}% off
                  </span>
                )}
              </div>
            </div>
            {/* {currencyCode} */}
            {/* {currencyCode === "RS" ? "." : ""} {price} */}
          </div>
        </div>
      </div>
    </div>
  )
}

const mapDispatchToProps = {}

export default connect(null, mapDispatchToProps)(ProductCardOne)

ProductCardOne.propTypes = {
  product: PropTypes.any,
  className: PropTypes.string,
}
