import React, { useRef, useEffect } from "react"
import { useState } from "react"
import chainImg from "assets/imgs/login/chain.png"
import { useOutsideAlerter } from "components/hooks/useOutSideClick"
import { companyName } from "project.config"
import PropTypes from "prop-types"
import Logo from "assets/imgs/logos/novita.svg"
import { FaAngleDoubleRight, FaSpinner } from "react-icons/fa"
import GoldAndWhite from "assets/imgs/other/white.jpeg"
import usePrice from "components/hooks/usePrice"
import { useDispatch, useSelector } from "react-redux"
import {
  hideMobileNavAction,
  loginUserMobile,
  loginUserOtp,
  showLoginPopupAction,
} from "store/actions"
import OtpInput from "react-otp-input"
import { IoIosCloseCircleOutline } from "react-icons/io"
const Popup = ({ showPopup, setShowPopup }) => {
  const dispatch = useDispatch()
  const wrapperRef = useRef(null)

  const { user, loading, otpError } = useSelector(state => ({
    user: state.Login.user,
    loading: state.Login.loading,
    otpError: state.Login.otp,
  }))

  const { currencyCode } = usePrice()

  const [outSide, setOutSide] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState(0)
  const [type, setType] = useState("login")
  const [otpText, setOtpText] = useState()

  const handleLoginPopup = () => {
    setShowPopup(false)
    dispatch(showLoginPopupAction())
  }

  const handleOtpChanges = v => {
    setOtpText(v)
  }

  useOutsideAlerter(wrapperRef, setOutSide)

  const handleClose = () => {
    setShowPopup(false)
  }

  useEffect(() => {
    if (outSide === true) {
      handleClose()
      setOutSide(false)
    }
  }, [outSide])

  const handleMobileLogin = e => {
    e.preventDefault()
    dispatch(loginUserMobile({ phone: phoneNumber }, "login"))
  }

  useEffect(() => {
    if (user?.userId) {
      setType("isOTP")
    } else {
      setType("login")
    }
  }, [user])

  useEffect(() => {
    if (user?.userId && otpText?.length === 4) {
      dispatch(
        loginUserOtp({ otp: otpText, userId: user?.userId }, setShowPopup)
      )
    }
  }, [otpText, dispatch])

  return (
    <>
      <div
        className={
          !showPopup
            ? "modal mobile-modal fade custom-modal d-none"
            : "modal mobile-modal fade custom-modal  show d-block"
        }
      >
        <div
          ref={wrapperRef}
          style={{
            height: "100%",
          }}
        >
          <div className="modal-dialog mobile_login d-none d-md-block">
            <div className="modal-content content-new d-flex flex-row ">
              <IoIosCloseCircleOutline
                className="popup-close-icon"
                onClick={handleClose}
              />
              <div className="modal-body image-div new-modal-body col-md-6">
                <img
                  src={chainImg}
                  alt={`${companyName}-login`}
                  title={`${companyName}-login`}
                  loading="eager"
                  className="popup-right-img"
                  style={{
                    borderRadius: 0,
                  }}
                />
              </div>

              <div className="modal-body myDeal-body p-0 col-md-6 ">
                <div
                  className="deal min-h-320px sm:bg-image-none d-flex align-items-center col-md-6 "
                  style={{
                    backgroundImage: `url(${GoldAndWhite})`,
                    backgroundSize: "cover",
                    height: "100%",
                  }}
                >
                  <div className="text-center ">
                    <img
                      className="popup-logo-img"
                      src={Logo}
                      alt="Logo"
                      style={{ width: "100px" }}
                    />

                    <p className="popup-p">LAUNCHING WITH JOY</p>
                    <h3>⁠Flat 20% Off </h3>

                    <p className="popup-p">
                      On your First purchase
                      <br />
                      when you sign up
                    </p>
                    {type === "isOTP" ? (
                      <>
                        <div className="deal-top">
                          <p className="mb-1">
                            Enter otp send to your mobile number
                          </p>
                        </div>
                        {/* <div className="deal-content  detail-info">
                          <div className="clearfix product-price-cover">
                            <form
                              method="post"
                              className="custom_form_login mt-3"
                            >
                              <div className="wrapper_otp ">
                                <label className="d-flex align-items-center justify-content-center mb-2">
                                  <h5 className="mb-0 pb-0 text-center">
                                    Enter Your OTP
                                  </h5>
                                  {loading && (
                                    <FaSpinner className="mx-2 spinner" />
                                  )}
                                </label>

                                <div className="d-flex align-items-center justify-content-center flex-column">
                                  <OtpInput
                                    value={otpText}
                                    onChange={v => handleOtpChanges(v)}
                                    numInputs={4}
                                    isInputNum={true}
                                    inputStyle={"otp_input"}
                                    containerStyle={"otp_container"}
                                    shouldAutoFocus={true}
                                    focusStyle={"otp_focus"}
                                  />
                                  {otpError?.message ? (
                                    <p className="text-end text-danger mb-0">
                                      {otpError?.message}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </form>
                          </div>
                        </div> */}
                      </>
                    ) : (
                      <div className="mx-5 mt-4">
                        {/* <form
                          method="post"
                          className="custom_form_login position-relative new-form"
                          onSubmit={e => handleMobileLogin(e)}
                        > */}
                        {/* <div className="d-flex flex-column">
                            <input
                              className="custom-input-popup"
                              type="number"
                              required={true}
                              minLength={10}
                              maxLength={10}
                              name="phone"
                              placeholder="Phone Number"
                              onChange={e => setPhoneNumber(e.target.value)}
                              onClick={() => dispatch(hideMobileNavAction())}
                            />
                          </div> */}

                        <button
                          type="button"
                          className="btn btn-primary custom-button-popup"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          onClick={handleLoginPopup}
                        >
                          ⁠SIGN UP{" "}
                          <FaAngleDoubleRight style={{ marginLeft: "5px" }} />
                        </button>
                        {/* </form> */}
                      </div>
                    )}

                    <p
                      className="mt-2 custom-underlined-text no-thanks cursor-pointer"
                      onClick={handleClose}
                    >
                      No, thanks
                    </p>
                  </div>
                </div>
              </div>
              {/* </div> */}
            </div>
          </div>
          {/* mobile */}
          <div className="modal-dialog mobile_login d-block d-md-none">
            <div
              className="modal-content content-new d-flex "
              style={{ backgroundColor: "white" }}
            >
              <IoIosCloseCircleOutline
                className="popup-close-icon"
                onClick={handleClose}
              />

              <div className="text-center mobile-popup-div ">
                <p className="popup-p">LAUNCHING WITH JOY</p>
                <h3 className="off-mobile">⁠Flat 20% Off </h3>

                <p className="popup-p">
                  On your First purchase
                  <br />
                  when you sign up
                </p>
                <div className="mx-5">
                  <div className="d-flex flex-column">
                    <input
                      type="text"
                      placeholder="First Name"
                      className="custom-input-popup"
                    />

                    <input
                      type="email"
                      placeholder="Enter Your Email Address"
                      className="custom-input-popup"
                    />
                  </div>

                  <button
                    onClick={handleLoginPopup}
                    className="btn mobile-btn btn-primary custom-button-popup"
                  >
                    ⁠SIGN UP <FaAngleDoubleRight />
                  </button>
                </div>

                <p
                  onClick={handleClose}
                  className="mt-2  custom-underlined-text no-thanks "
                >
                  No, thanks
                </p>
                {/* <p className="mt-2  applicable">
                  Only applicable for purchase <br /> above {currencyCode}
                  {currencyCode === "RS" ? "." : ""} 800/-
                </p> */}
                <div className="logo-div-mob mt-2">
                  <img
                    className="popup-logo-img"
                    src={Logo}
                    alt="Logo"
                    style={{ width: "100px" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={
          !showPopup
            ? "modal-backdrop mobile-null-opacity fade d-none"
            : "modal-backdrop mobile-null-opacity fade show"
        }
      ></div>
    </>
  )
}

export default Popup
Popup.propTypes = {
  setShowPopup: PropTypes.func,
  showPopup: PropTypes.bool,
}
