import React, { useState, useEffect } from "react"
import Popup from "./Popup" // Adjust the import path based on your project structure

const PopupWrapper = () => {
  const [showPopup, setShowPopup] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      console.log("Setting showPopup to true")
      setShowPopup(true)
    }, 5000)

    return () => {
      clearTimeout(timer)
    }
  }, [])

  return (
    <>
      {showPopup && (
        <>
          <Popup showPopup={showPopup} setShowPopup={setShowPopup} />
        </>
      )}
    </>
  )
}

export default PopupWrapper
