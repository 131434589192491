import React, { useRef, useEffect } from "react"
import { useState } from "react"
import LoginBg from "assets/imgs/login/login.png"
import { FaSpinner } from "react-icons/fa"
import { AiOutlineUsergroupAdd } from "react-icons/ai"
import loginImg from "assets/imgs/login/login.png"
import chainImg from "assets/imgs/login/chain.png"
import GoldAndWhite from "assets/imgs/other/white.jpeg"

import { useDispatch, useSelector } from "react-redux"
import {
  hideLoginPopupAction,
  hideMobileNavAction,
  loginUserMobile,
  loginUserOtp,
  showMobileNavAction,
} from "store/actions"
import OtpInput from "react-otp-input"
import { useOutsideAlerter } from "components/hooks/useOutSideClick"
import { useWindowSize } from "react-use"
import LazyLoad from "react-lazyload"
import { companyName } from "project.config"

const LoginPopup = () => {
  const dispatch = useDispatch()
  const wrapperRef = useRef(null)

  const { user, loading, otpError, isLoginPopup, company } = useSelector(
    state => ({
      user: state.Login.user,
      loading: state.Login.loading,
      otpError: state.Login.otp,
      isLoginPopup: state.Layout.isLoginPopup,
      company: state?.SiteSettings.siteSettings?.company,
    })
  )

  const [phoneNumber, setPhoneNumber] = useState(0)
  const [refferalCode, setRefferalCode] = useState(0)
  const [penTag, setPenTag] = useState(false)
  const [refferalCodeTag, setRefferalCodeTag] = useState(false)

  const [type, setType] = useState("login")

  const [outSide, setOutSide] = useState(false)

  useOutsideAlerter(wrapperRef, setOutSide)

  const handleClose = () => {
    dispatch(hideLoginPopupAction())
    dispatch(showMobileNavAction())
  }

  const handleMobileLogin = e => {
    e.preventDefault()
    dispatch(loginUserMobile({ phone: phoneNumber }, "login"))
  }

  const handleRegister = () => {
    dispatch(
      loginUserMobile(
        { phone: phoneNumber, referralcode: refferalCode },
        "register"
      )
    )
  }

  const [otpText, setOtpText] = useState()

  const handleOtpChnages = v => {
    setOtpText(v)
  }

  useEffect(() => {
    if (user?.userId && otpText?.length === 4) {
      dispatch(loginUserOtp({ otp: otpText, userId: user?.userId }, ""))
    }
  }, [otpText, dispatch])

  useEffect(() => {
    if (!user?.userId && user?.message) {
      setType("register")
    } else if (user?.userId) {
      setType("isOTP")
    } else {
      setType("login")
    }
  }, [user])

  useEffect(() => {
    if (outSide === true) {
      handleClose()
      setOutSide(false)
    }
  }, [outSide])

  const { width } = useWindowSize()

  useEffect(() => {
    if (type === "isOTP") {
      dispatch(hideMobileNavAction())
    } else {
      dispatch(showMobileNavAction())
    }
  }, [dispatch, type])

  return (
    <>
      <div
        className={
          !isLoginPopup
            ? "modal fade custom-modal d-none"
            : "modal fade custom-modal  show d-block"
        }
      >
        <div className="modal-dialog mobile_login">
          <div
            className="modal-content p-0"
            ref={wrapperRef}
            style={{ backgroundColor: "unset", borderRadius: "0" }}
          >
            <button
              type="button"
              className="btn-close"
              onClick={handleClose}
            ></button>
            <div></div>
            <div className="myDeal d-none">
              {/* {type === "register" ? (
                <h2 className="">Register</h2>
              ) : (
                <h2 className="">Login</h2>
              )} */}
              <LazyLoad height={200}>
                <img
                  src={loginImg}
                  alt={`${companyName}-login`}
                  title={`${companyName}-login`}
                  loading="eager"
                  style={{
                    borderRadius: "0",
                  }}
                />
              </LazyLoad>
            </div>
            <div className="d-flex justify-content-center text-align-center custom-bg">
              {/* <div className="modal-body p-0  col-md-6"> */}
              {/* <LazyLoad className="col-md-6" style={{ objectFit: "contain" }}> */}
              <img
                className="col-md-6 custom-rounded-start"
                src={chainImg}
                alt={`${companyName}-login`}
                title={`${companyName}-login`}
                loading="eager"
                style={{ display: width <= 480 ? "none" : "" }}
              />
              {/* </LazyLoad> */}
              {/* </div> */}
              <div
                className="modal-body myDeal-body col-md-6 py-auto d-flex justify-content-center "
                style={{
                  backgroundColor: "white",
                  borderTopRightRadius: "15px",
                  borderBottomRightRadius: "15px",
                  padding: 0,
                }}
              >
                <div
                  className="deal px-5 min-h-320px sm:bg-image-none d-flex align-items-center"
                  style={{
                    backgroundImage: `url(${GoldAndWhite})`,
                    backgroundSize: "cover",
                    height: "100%",
                    borderRadius: "0",
                  }}
                >
                  {type === "isOTP" ? (
                    <>
                      <div className="deal-top">
                        <p className="mb-1">
                          Enter otp send to your mobile number
                        </p>
                        <p>
                          or Re-ender your{" "}
                          <u
                            className="text-primary"
                            onClick={() => setType("login")}
                          >
                            {" "}
                            Mobile number{" "}
                          </u>
                        </p>
                      </div>
                      <div className="deal-content  detail-info">
                        <div className="clearfix product-price-cover">
                          <form method="post" className="custom_form_login">
                            <div className="wrapper_otp ">
                              <label className="d-flex align-items-center mb-2 justify-content-center">
                                <h5 className="mb-0 pb-0">Enter Your OTP</h5>
                                {loading && (
                                  <FaSpinner className="mx-2 spinner" />
                                )}
                              </label>

                              <div className="d-flex align-items-start flex-column">
                                <OtpInput
                                  value={otpText}
                                  onChange={v => handleOtpChnages(v)}
                                  numInputs={4}
                                  isInputNum={true}
                                  inputStyle={"otp_input"}
                                  containerStyle={"otp_container"}
                                  shouldAutoFocus={true}
                                  focusStyle={"otp_focus"}
                                />
                                {otpError?.message ? (
                                  <p className="text-center text-black mb-0">
                                    {otpError?.message}
                                  </p>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div></div>
                      <div className="deal-top text-center">
                        <>
                          {type === "register" ? (
                            <h2 className="text-brand">Register</h2>
                          ) : (
                            <h2 className="text-brand">Login</h2>
                          )}
                        </>
                        <h5
                          className="mb-1 text-center w-100 mt-3"
                          style={{ maxWidth: "100%" }}
                        >
                          Enter your mobile number
                        </h5>
                        {type === "register" ? (
                          <p
                            onClick={() => setType("login")}
                            className="mb-4  "
                          >
                            already have an account?&nbsp;
                            <u className="text-primary cursor-pointer">
                              Login Now
                            </u>
                          </p>
                        ) : (
                          <p
                            onClick={() => setType("register")}
                            className="mb-4 sm:mb-0"
                          >
                            New to {company?.name} ?{" "}
                            <u className="new-register cursor-pointer">
                              Register Now
                            </u>
                          </p>
                        )}
                      </div>
                      <div className="deal-content  detail-info ">
                        <div className="clearfix product-price-cover">
                          <form
                            method="post"
                            className="custom_form_login position-relative new-form"
                            onSubmit={e => handleMobileLogin(e)}
                          >
                            <div className="form-group mb-2 d-flex align-items-center justify-end">
                              <span className="text-size-20 mr-1">+91</span>
                              <input
                                type="number"
                                required={true}
                                minLength={10}
                                maxLength={10}
                                name="phone"
                                placeholder="Phone Number"
                                onChange={e => setPhoneNumber(e.target.value)}
                                onClick={() => dispatch(hideMobileNavAction())}
                                style={{
                                  borderRadius: 4,
                                  backgroundColor: "#fff",
                                }}
                              />
                            </div>
                            {type === "register" && (
                              <AiOutlineUsergroupAdd
                                size={"1.5rem"}
                                className="position-absolute positioning tada cursor-pointer"
                                title="Do you have a refferal code?"
                                onMouseOver={() => setPenTag(true)}
                                onMouseLeave={() => setPenTag(false)}
                                onClick={() =>
                                  setRefferalCodeTag(!refferalCodeTag)
                                }
                              />
                            )}

                            {refferalCodeTag && (
                              <div className="form-group mb-2 d-flex align-items-center justify-end">
                                <span className="text-size-16 mr-1"></span>
                                <input
                                  type="phone"
                                  name="referralcode"
                                  placeholder="Referral Code"
                                  onChange={e =>
                                    setRefferalCode(e.target.value)
                                  }
                                  onClick={() =>
                                    dispatch(hideMobileNavAction())
                                  }
                                  className=""
                                />
                              </div>
                            )}

                            {penTag && (
                              <div className="penTag">
                                Do you have a refferal code?
                              </div>
                            )}

                            {type === "register" ? (
                              <p className="text-end text-brand mb-4">
                                {user?.message
                                  ? `New To ${companyName}? Please Register Now`
                                  : ""}
                              </p>
                            ) : (
                              ""
                            )}

                            <div className="form-group d-flex align-items-center justify-center">
                              {type === "register" ? (
                                <button
                                  type="button"
                                  className="btn btn-primary custom-button-popup d-flex align-items-center justify-content-center w-100"
                                  name="login"
                                  onClick={handleRegister}
                                >
                                  Register
                                  {loading && (
                                    <FaSpinner className="mx-2 spinner" />
                                  )}
                                </button>
                              ) : (
                                <button
                                  type="submit"
                                  className="btn btn-primary custom-button-popup d-flex align-items-center justify-content-center w-100"
                                >
                                  Login
                                  {loading && (
                                    <FaSpinner className="mx-2 spinner" />
                                  )}
                                </button>
                              )}
                            </div>
                          </form>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            {type === "register" && (
              <p
                className="text-end mx-3 mt-1 termsOfUse font-smaller"
                style={{
                  position: "absolute",
                  bottom: 0,
                  right: 0,
                }}
              >
                By clicking on &#39;Register&#39; you are agreeing to our{" "}
                <u className="text-primary">terms of use</u>{" "}
              </p>
            )}
          </div>
        </div>
      </div>

      <div
        className={
          !isLoginPopup
            ? "modal-backdrop fade d-none"
            : "modal-backdrop fade show"
        }
      ></div>
    </>
  )
}

export default LoginPopup
