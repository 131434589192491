import React from "react"
import PropTypes from "prop-types"

import empty from "assets/imgs/shop/cart-empty.gif"
import { Link } from "react-router-dom"
import LazyLoad from "react-lazyload"

function EmptyCartSvg({ style }) {
  return (
    <>
      <div
        className="d-flex align-items-center flex-revers-col emptyCart"
        style={style}
      >
        <div>
          {style?.isDes ? (
            <>
              <h4 className="text-brand cartempty">Your cart is empty!</h4>
              <p className="text-black cartemptySub">
                “Explore our wide selection and find something you like”
              </p>
              <Link
                to={{
                  pathname: "/products",
                  query: {
                    prod: "all",
                  },
                }}
                className="m-auto w-fit"
              >
                <span className="btn btn-xs submit-auto-width font-smaller hover-up mt-30">
                  <i className="fi-rs-home mr-5"></i> Back To Shopping
                </span>
              </Link>
            </>
          ) : (
            <p className="text-brand">Your cart is empty!</p>
          )}
        </div>
        {!style?.isDes ? (
          <LazyLoad height={200} className="mt-4">
            <img
              src={empty}
              alt="empty-pic"
              className="h-100"
              height="400"
              width="400"
              title="cart-empty"
              loading="eager"
            />
          </LazyLoad>
        ) : (
          ""
        )}
      </div>
      {!style?.isDes && (
        <Link
          to={{
            pathname: "/products",
            query: {
              prod: "all",
            },
          }}
          className="m-auto w-fit"
        >
          <span className="btn btn-xs submit-auto-width font-smaller hover-up mt-30">
            <i className="fi-rs-home mr-5"></i> Back To Shopping
          </span>
        </Link>
      )}
    </>
  )
}

export default EmptyCartSvg

EmptyCartSvg.propTypes = {
  style: PropTypes.any,
}
