module.exports = {
  primaryColor: "#22b3be",
  secondaryColor: "ed475f",
  companyName: "Novita",
  shortName: "Novita",
  siteMetadata: {
    websiteTitle: "Novita",
    description: "Novita by Wamier",
    keywords: "",
    faviconUrl: "",
    logoUrl: "",
    secondaryLogo: "",
  },
  apiEndpointDev: "https://api.novitastore.in/",
  apiEndpointProd: "http://192.168.1.23:4005",
  frontEndUrl: "https://www.novita.in",
  tokenPrefix: "novita_token",
  emailApiKey: "f83ad697-029f-4f94-9987-58a2b37f3ac1",
}
