import React from "react"
import { LogoSm1, SubLogo } from "assets/imgs/logos"
import { Link, useLocation } from "react-router-dom"
import { Col, Row } from "reactstrap"
import facebook from "assets/imgs/theme/icons/icon-facebook-white.svg"
import instagram from "assets/imgs/theme/icons/icon-instagram-white.svg"
import { FaLinkedinIn, FaTiktok, FaWhatsapp } from "react-icons/fa"
import { RiSnapchatLine } from "react-icons/ri"
import { useDispatch, useSelector } from "react-redux"
import { FaFacebook, FaInstagram } from "react-icons/fa"
import LazyLoad from "react-lazyload"
import { companyName } from "project.config"
import LogoSquare from "assets/imgs/logos/NOVITA SQUARE.svg"
import { TOKEN_PREFIX } from "helpers/api_methods"
import { showLoginPopupAction } from "store/actions"
function Footer() {
  const dispatch = useDispatch()

  const { socialLinks, company, loadingProd } = useSelector(state => ({
    company: state?.SiteSettings.siteSettings?.company,
    socialLinks: state.SiteSettings.siteSettings.socialLinks,
    loadingProd: state.Products.loading,
  }))
  const token = localStorage.getItem(TOKEN_PREFIX)
  const handleLoginPopup = () => dispatch(showLoginPopupAction())
  return (
    <>
      {/* {!loadingProd && location.pathname === "/" && (
        <section className="newsletter mb-15  wow animate__animated animate__fadeIn pt-4">
          <div className="container">
            <div className="row ">
              <div className="col-lg-6">
                <div className="newsletter-content">
                  <LazyLoad height={200}>
                    <img
                      src={bannerBottom2}
                      alt={`${companyName}-newsletter`}
                      title={`${companyName}-newsletter`}
                      loading="eager"
                      className="border-radious-small max-h-360 bannerFooterImg"
                    />
                  </LazyLoad>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="newsletter-content">
                  <LazyLoad height={200}>
                    <img
                      src={bannerBottom}
                      alt={`${companyName}-newsletter`}
                      title={`${companyName}-newsletter`}
                      loading="eager"
                      className="border-radious-small max-h-360 bannerFooterImg"
                    />
                  </LazyLoad>
                </div>
              </div>
            </div>
          </div>
        </section>
      )} */}
      <div className="footer">
        <Row className="m-0 footer-margin">
          <Col
            lg={4}
            className="sm:p-0 d-flex align-item-center footer-logo-div"
          >
            <div className="d-flex align-items-center logos">
              <Link to={"/"}>
                <LazyLoad height={156}>
                  <img
                    src={LogoSquare}
                    alt="footer-logo"
                    className="logo-footer"
                    loading="lazy"
                    width={127}
                    height={144}
                  />
                </LazyLoad>
              </Link>
              {/* <div className="footerAddress sm:m-0">
                <p>Address : {company?.address?.addressLine1}</p>
                <p> {company?.address?.addressLine2}</p>
                <p>Call Us : {company?.contactDetails?.mobile}</p>
                <p> Email : {company?.contactDetails?.email}</p>
                <div className="mobile-social-icon d-flex align-items-center">
                  {socialLinks?.facebook && (
                    <a
                      href={socialLinks?.facebook}
                      target="_blank"
                      className="d-flex align-items-center justify-content-center"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={facebook}
                        alt="facebook"
                        title={`${companyName}-facebook`}
                        loading="eager"
                      />
                    </a>
                  )}
                  {socialLinks?.instagram && (
                    <a
                      href={socialLinks?.instagram}
                      target="_blank"
                      className="d-flex align-items-center justify-content-center"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={instagram}
                        alt="instagram"
                        title={`${companyName}-instagram`}
                        loading="eager"
                      />
                    </a>
                  )}

                  {socialLinks?.linkedin && (
                    <a
                      href={socialLinks?.linkedin}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="d-flex align-items-center justify-content-center"
                    >
                      <FaLinkedinIn color="white" />
                    </a>
                  )}

                  {socialLinks?.whatsApp && (
                    <a
                      href={socialLinks?.whatsApp}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="d-flex align-items-center justify-content-center"
                    >
                      <FaWhatsapp color="white" />
                    </a>
                  )}
                  {socialLinks?.snapchat && (
                    <a
                      href={socialLinks?.snapchat}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="d-flex align-items-center justify-content-center"
                    >
                      <RiSnapchatLine color="white" />
                    </a>
                  )}
                  {socialLinks?.tiktok && (
                    <a
                      href={socialLinks?.tiktok}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="d-flex align-items-center justify-content-center"
                    >
                      <FaTiktok color="white" />
                    </a>
                  )}
                </div>
              </div> */}
            </div>
          </Col>
          <Col lg={8} className="p-0">
            <div className="d-flex footer-main-section-div">
              <div
                className="footer-link-widget col col-xs-6 wow animate__animated animate__fadeInUp"
                data-wow-delay=".1s"
              >
                <h3 className="widget-title h5">Menu</h3>
                <ul className="footer-list  mb-sm-5 mb-md-0">
                  {/* <li>
                    <Link className="footer-link" to="/">
                      Search
                    </Link>
                  </li> */}
                  {/* <li>
                    <Link className="footer-link" to="/">
                      About Us
                    </Link>
                  </li> */}
                  {token ? (
                    <li>
                      <Link className="footer-link" to="/user-account">
                        Track Order
                      </Link>
                    </li>
                  ) : (
                    <li>
                      <Link
                        className="footer-link"
                        to="#"
                        onClick={handleLoginPopup}
                      >
                        Track Order
                      </Link>
                    </li>
                  )}
                  <li>
                    <Link className="footer-link" to="/privacy-policy">
                      Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link className="footer-link" to="/return-policy">
                      Return & Refund Policy
                    </Link>
                  </li>
                  <li>
                    <Link className="footer-link" to="/privacy-policy">
                      Shipping Policy
                    </Link>
                  </li>
                  <li>
                    <Link className="footer-link" to="/terms">
                      Terms & Service
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                className="footer-link-widget col col-xs-6 wow animate__animated animate__fadeInUp"
                data-wow-delay=".1s"
              >
                <h3 className="widget-title h5">About Us</h3>
                <p>
                  Novita was established in 2020. It is an exclusive online
                  store for designer and oneof-a kind jewellery. novita was
                  developed to provide women with access to modern, quality
                  jewellery that was also worn on a daily basis.
                </p>
                <div className="mt-2">
                  {socialLinks?.facebook && (
                    <a
                      href={socialLinks?.facebook}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaFacebook
                        size={15}
                        className="social-icon-footer me-2"
                      />
                    </a>
                  )}
                  {socialLinks?.instagram && (
                    <a
                      href={socialLinks?.instagram}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaInstagram
                        size={15}
                        className="social-icon-footer me-2"
                      />
                    </a>
                  )}

                  {socialLinks?.linkedin && (
                    <a
                      href={socialLinks?.linkedin}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaLinkedinIn
                        size={15}
                        className="social-icon-footer me-2"
                      />
                    </a>
                  )}

                  {socialLinks?.whatsApp && (
                    <a
                      href={socialLinks?.whatsApp}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaWhatsapp
                        size={15}
                        className="social-icon-footer me-2"
                      />
                    </a>
                  )}
                  {socialLinks?.snapchat && (
                    <a
                      href={socialLinks?.snapchat}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <RiSnapchatLine
                        size={15}
                        className="social-icon-footer me-2"
                      />
                    </a>
                  )}
                  {socialLinks?.tiktok && (
                    <a
                      href={socialLinks?.tiktok}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaTiktok size={15} className="social-icon-footer" />
                    </a>
                  )}
                </div>
                {/* <ul className="footer-list  mb-sm-5 mb-md-0">
                  <li>
                    <Link to="/user-account">Account</Link>
                  </li>
                  <li>
                    <Link to="/cart">View Cart</Link>
                  </li>
                  <li>
                    <Link to="/wishlist">My Wishlist</Link>
                  </li>
                  <li>
                    <Link
                      to={{
                        pathname: "/user-account",
                        state: { index: 2 },
                      }}
                    >
                      My Order
                    </Link>
                  </li>
                </ul> */}
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div className="footer-bottom">
        <Row className="d-flex justify-content-center text-center">
          <p className="footer-text">© 2023, Novita Online Powered by Wamier</p>
        </Row>
      </div>
    </>
  )
}

export default Footer
